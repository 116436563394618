import { render, staticRenderFns } from "./Lgx279928282168688640.vue?vue&type=template&id=15604193&scoped=true&"
import script from "./Lgx279928282168688640.vue?vue&type=script&lang=js&"
export * from "./Lgx279928282168688640.vue?vue&type=script&lang=js&"
import style0 from "./Lgx279928282168688640.vue?vue&type=style&index=0&id=15604193&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "15604193",
  null
  
)

export default component.exports