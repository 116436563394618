<template>
  <div>
    <vx-card>
      <div class="vx-row">
        <div class="vx-col w-1/2 flex justify-end items-center">
          <div class="flex items-center justify-center">
            <img
              style="max-height: 600px; max-width: 100%"
              class="responsive"
              src="https://connect-cdn.intellectualpoint.com/assets/images/simulations/network-plus/279928282168688640/figure.png"
              alt="Diagram"
            />
          </div>
        </div>
        <div class="vx-col w-1/2 flex justify-start items-center">
          <div class="border border-solid rounded-lg flex flex-col justify-center p-6 h-full" style="background: #383838">
            <div class="flex justify-center items-center mb-2">
              <img
                src="https://connect-cdn.intellectualpoint.com/assets/images/simulations/network-plus/279928282168688640/technician.png"
                class="mr-2"
                width="45"
                alt=""
              />
              <h1 class="font-semibold">Wireless Technician PC</h1>
            </div>

            <div
              class="controlPanel cursor-pointer"
              v-for="panel in panels"
              :key="panel.name"
              :class="panel.color"
              @click="panel.popup = !panel.popup"
            >
              <div class="flex justify-between items-center">
                <div class="mr-10">
                  <div>
                    <span class="h1 font-bold" :class="`text-${panel.color}`">{{ panel.name.toUpperCase() }} AP</span>
                  </div>
                  <div><span class="h1 font-bold" :class="`text-${panel.color}`">CONTROL PANEL</span></div>
                </div>
                <img
                  width="70"
                  src="https://connect-cdn.intellectualpoint.com/assets/images/simulations/network-plus/279928282168688640/wireless-router.png"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </vx-card>

    <movable-popup
      v-for="(panel, panelI) in panels"
      :key="panel.name"
      :active="panel.popup"
      v-on:close="panel.popup = false"
      :refVal="`Movable${panel.name}Ref`"
      width="40vw"
      padding="0"
      main-bg="transparent"
      :title="`Configure ${panel.name} - Click Here to Drag This Popup`"
      :blur="15"
      :top-bg="panelColor(panel.color)"
      :margin-left="panelI * 50"
    >
      <template v-slot:content>
        <vx-card style="backdrop-filter: blur(50px); background-color: rgb(0 0 0 / 80%)" :title="`${panel.name} Control Panel`">
          <vs-tabs :color="panelColor(panel.color)" class="overflow-hidden">
            <vs-tab :label="tab.name" icon-pack="feather" :icon="tab.icon" v-for="(tab, tIndex) in panel.properties" :key="tIndex">
              <div class="pt-2 popup-input" style="min-height: 420px">
                <div class="grid grid-cols-1 gap-4">
                  <div v-for="(field, fieldI) in tab.fields" :key="fieldI" class="mt-4">
                    <div class="" v-if="field.type === 'html'" v-html="field.html"></div>
                    <div class="vx-row" v-if="field.type !== 'html'">
                      <div class="vx-col flex items-center w-2/5">
                        <feather-icon
                          v-if="field.score === true"
                          icon="CheckCircleIcon"
                          svgClasses="w-8 h-8 mr-2 text-success self-center"
                        />
                        <feather-icon v-if="field.score === false" icon="XCircleIcon" svgClasses="w-8 h-8 mr-2 text-danger self-center" />
                        <h3 class="break-all self-center">{{ field.name }}</h3>
                      </div>
                      <div class="vx-col w-3/5">
                        <v-select
                          v-if="field.type === 'dropdown'"
                          :options="field.options"
                          v-model="field.value"
                          :disabled="field.disabled"
                          class="w-full self-center border border-solid rounded"
                          placeholder="Select Option From Dropdown..."
                          size="large"
                        />
                        <vs-input
                          v-if="field.type === 'text'"
                          v-model="field.value"
                          :readonly="field.disabled"
                          :color="panelColor(panel.color)"
                          class="inputx w-full self-center"
                        />
                        <div class="flex items-center" v-if="field.type === 'switch'">
                          <vs-switch
                            v-model="field.value"
                            :disabled="field.disabled"
                            :color="panelColor(panel.color)"
                            class="self-center mr-2"
                          />
                          <h4 class="ml-2">{{ field.desc }}</h4>
                        </div>
                        <!--- <small v-if="field.score === false"
                               class="mt-2 text-danger font-semibold">This answer is incorrect</small>
                        <small v-if="field.score === true"
                               class="mt-2 text-success font-semibold">This answer is correct</small> --->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </vs-tab>
          </vs-tabs>
        </vx-card>
      </template>
    </movable-popup>
  </div>
</template>

<script>
import vSelect from 'vue-select';
import MovablePopup from '../../../../../../../../components/popup/MovablePopup';

export default {
  data() {
    return {
      dataLocal: JSON.parse(JSON.stringify(this.data)),

      panels: [
        {
          name: 'Aspire',
          color: 'green',
          popup: false,
          properties: [
            {
              name: 'Configuration',
              icon: 'icon-settings',
              fields: [
                {
                  name: 'Access Point Name',
                  type: 'text',
                  value: 'ASPIRE-AP',
                  answer: ['ASPIRE-AP'],
                  disabled: false,
                  score: null,
                },
                {
                  name: 'IP Address',
                  type: 'text',
                  value: '',
                  answer: ['10.60.100.11'],
                  disabled: false,
                  score: null,
                },
                {
                  name: 'Gateway',
                  type: 'text',
                  value: '',
                  answer: ['10.60.100.1'],
                  disabled: false,
                  score: null,
                },
                {
                  name: 'SSID',
                  type: 'text',
                  value: '',
                  answer: ['GENETIC-LAB'],
                  disabled: false,
                  score: null,
                },
                {
                  name: 'Enable SSID Broadcast',
                  type: 'switch',
                  value: false,
                  answer: [true],
                  disabled: false,
                  score: null,
                },
              ],
            },
            {
              name: 'Internet',
              icon: 'icon-wifi',
              fields: [
                {
                  name: 'Wireless Mode',
                  type: 'dropdown',
                  value: '',
                  answer: ['G'],
                  disabled: false,
                  options: ['B', 'G', 'BG', 'N', 'Mixed', 'Disabled'],
                  score: null,
                },
                {
                  name: 'Wireless Channel',
                  type: 'dropdown',
                  value: '',
                  answer: ['1', '6', '11'],
                  unique: 'channels',
                  disabled: false,
                  options: ['1', '2', '3', '5', '6', '7', '8', '9', '10', '11', '12'],
                  score: null,
                },
                {
                  name: 'Wired Speed',
                  type: 'dropdown',
                  value: '',
                  answer: ['Auto'],
                  disabled: false,
                  options: ['Auto', '100', '1000'],
                  score: null,
                },
                {
                  name: 'Wired Duplex',
                  type: 'dropdown',
                  value: '',
                  answer: ['Auto'],
                  disabled: false,
                  options: ['Auto', 'Full', 'Half'],
                  score: null,
                },
              ],
            },
            {
              name: 'Security',
              icon: 'icon-shield',
              fields: [
                {
                  name: 'Security Settings',
                  type: 'dropdown',
                  value: '6',
                  answer: ['WPA'],
                  disabled: false,
                  options: ['OPEN', 'WEP', 'WPA', 'WPA2', 'WPA2 Enterprise'],
                  score: null,
                },
                {
                  name: 'Key or Passphrase',
                  type: 'text',
                  value: '',
                  answer: ['G3neti(!Ab'],
                  disabled: false,
                  score: null,
                },
              ],
            },
          ],
        },
        {
          name: 'Elevate',
          color: 'blue',
          popup: false,
          properties: [
            {
              name: 'Configuration',
              icon: 'icon-settings',
              fields: [
                {
                  name: 'Access Point Name',
                  type: 'text',
                  value: 'ELEVATE-AP',
                  answer: ['ELEVATE-AP'],
                  disabled: false,
                  score: null,
                },
                {
                  name: 'IP Address',
                  type: 'text',
                  value: '',
                  answer: ['10.60.100.12'],
                  disabled: false,
                  score: null,
                },
                {
                  name: 'Gateway',
                  type: 'text',
                  value: '',
                  answer: ['10.60.100.1'],
                  disabled: false,
                  score: null,
                },
                {
                  name: 'SSID',
                  type: 'text',
                  value: '',
                  answer: ['GENETIC-LAB'],
                  disabled: false,
                  score: null,
                },
                {
                  name: 'Enable SSID Broadcast',
                  type: 'switch',
                  value: false,
                  answer: [true],
                  disabled: false,
                  score: null,
                },
              ],
            },
            {
              name: 'Internet',
              icon: 'icon-wifi',
              fields: [
                {
                  name: 'Wireless Mode',
                  type: 'dropdown',
                  value: '',
                  answer: ['G'],
                  disabled: false,
                  options: ['B', 'G', 'BG', 'N', 'Mixed', 'Disabled'],
                  score: null,
                },
                {
                  name: 'Wireless Channel',
                  type: 'dropdown',
                  value: '',
                  answer: ['1', '6', '11'],
                  unique: 'channels',
                  disabled: false,
                  options: ['1', '2', '3', '5', '6', '7', '8', '9', '10', '11', '12'],
                  score: null,
                },
                {
                  name: 'Wired Speed',
                  type: 'dropdown',
                  value: '',
                  answer: ['Auto'],
                  disabled: false,
                  options: ['Auto', '100', '1000'],
                  score: null,
                },
                {
                  name: 'Wired Duplex',
                  type: 'dropdown',
                  value: '',
                  answer: ['Auto'],
                  disabled: false,
                  options: ['Auto', 'Full', 'Half'],
                  score: null,
                },
              ],
            },
            {
              name: 'Security',
              icon: 'icon-shield',
              fields: [
                {
                  name: 'Security Settings',
                  type: 'dropdown',
                  value: '6',
                  answer: ['WPA'],
                  disabled: false,
                  options: ['OPEN', 'WEP', 'WPA', 'WPA2', 'WPA2 Enterprise'],
                  score: null,
                },
                {
                  name: 'Key or Passphrase',
                  type: 'text',
                  value: '',
                  answer: ['G3neti(!Ab'],
                  disabled: false,
                  score: null,
                },
              ],
            },
          ],
        },
        {
          name: 'Ignite',
          color: 'orange',
          popup: false,
          properties: [
            {
              name: 'Configuration',
              icon: 'icon-settings',
              fields: [
                {
                  name: 'Access Point Name',
                  type: 'text',
                  value: 'IGNITE-AP',
                  answer: ['IGNITE-AP'],
                  disabled: false,
                  score: null,
                },
                {
                  name: 'IP Address',
                  type: 'text',
                  value: '',
                  answer: ['10.60.100.13'],
                  disabled: false,
                  score: null,
                },
                {
                  name: 'Gateway',
                  type: 'text',
                  value: '',
                  answer: ['10.60.100.1'],
                  disabled: false,
                  score: null,
                },
                {
                  name: 'SSID',
                  type: 'text',
                  value: '',
                  answer: ['GENETIC-LAB'],
                  disabled: false,
                  score: null,
                },
                {
                  name: 'Enable SSID Broadcast',
                  type: 'switch',
                  value: false,
                  answer: [true],
                  disabled: false,
                  score: null,
                },
              ],
            },
            {
              name: 'Internet',
              icon: 'icon-wifi',
              fields: [
                {
                  name: 'Wireless Mode',
                  type: 'dropdown',
                  value: '',
                  answer: ['G'],
                  disabled: false,
                  options: ['B', 'G', 'BG', 'N', 'Mixed', 'Disabled'],
                  score: null,
                },
                {
                  name: 'Wireless Channel',
                  type: 'dropdown',
                  value: '',
                  answer: ['1', '6', '11'],
                  unique: 'channels',
                  disabled: false,
                  options: ['1', '2', '3', '5', '6', '7', '8', '9', '10', '11', '12'],
                  score: null,
                },
                {
                  name: 'Wired Speed',
                  type: 'dropdown',
                  value: '',
                  answer: ['Auto'],
                  disabled: false,
                  options: ['Auto', '100', '1000'],
                  score: null,
                },
                {
                  name: 'Wired Duplex',
                  type: 'dropdown',
                  value: '',
                  answer: ['Auto'],
                  disabled: false,
                  options: ['Auto', 'Full', 'Half'],
                  score: null,
                },
              ],
            },
            {
              name: 'Security',
              icon: 'icon-shield',
              fields: [
                {
                  name: 'Security Settings',
                  type: 'dropdown',
                  value: '6',
                  answer: ['WPA'],
                  disabled: false,
                  options: ['OPEN', 'WEP', 'WPA', 'WPA2', 'WPA2 Enterprise'],
                  score: null,
                },
                {
                  name: 'Key or Passphrase',
                  type: 'text',
                  value: '',
                  answer: ['G3neti(!Ab'],
                  disabled: false,
                  score: null,
                },
              ],
            },
          ],
        },
      ],

      unique: {
        channels: [],
      },
    };
  },
  methods: {
    panelColor(panel) {
      if (panel === 'green') return '#1BD3BF';
      if (panel === 'blue') return '#3166C2';
      if (panel === 'orange') return '#E77A36';
      return '';
    },
    markSimulation() {
      let totalScore = 0;

      for (const [key] of Object.entries(this.unique)) {
        this.unique[key] = [];
      }

      this.panels.forEach((panel, panelI) => {
        panel.properties.forEach((properties, propertiesI) => {
          if (properties.fields) {
            properties.fields.forEach((field, fieldI) => {
              if (field.type !== 'html') {
                if (field.unique && this.unique[field.unique].includes(field.value)) {
                  this.panels[panelI].properties[propertiesI].fields[fieldI].score = false;
                  return;
                }
                if (field.answer.includes(field.value)) {
                  if (field.unique) this.unique[field.unique].push(field.value);
                  this.panels[panelI].properties[propertiesI].fields[fieldI].score = true;
                  totalScore++;
                } else {
                  this.panels[panelI].properties[propertiesI].fields[fieldI].score = false;
                }
              }
            });
          }
        });
      });

      if (totalScore === this.dataLocal.simulation.totalScore) {
        return this.$emit('simulation_full_correct', totalScore);
      }
      this.$emit('simulation_getting_there', totalScore);
    },
  },
  components: {
    vSelect,
    MovablePopup,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.controlPanel {
  background: #232323;
  padding: 1.5rem 3rem 1.5rem 3rem;
  margin: 1rem;
  border-radius: 0.5rem;

  &.green {
    box-shadow: 0px 0px 15px 3px rgba(107, 221, 169, 0.55);
  }

  &.blue {
    box-shadow: 0px 0px 15px 3px rgba(83, 176, 235, 0.55);
  }

  &.orange {
    box-shadow: 0px 0px 15px 3px rgba(228, 140, 67, 0.55);
  }

  .text-green {
    padding-right: 0.05em;
    background-image: linear-gradient(243deg, #1cd6bb 23%, #06a9f0 87%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .text-blue {
    padding-right: 0.05em;
    background-image: linear-gradient(243deg, #12c2ff 23%, #416cff 87%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .text-orange {
    padding-right: 0.05em;
    background-image: linear-gradient(243deg, #fd654e 23%, #e0812e 87%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}
</style>
